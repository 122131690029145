import React from "react";

function MeteoHourlyCard(props) {

    function timeConversion(timeStamp) {
        const date = new Date(timeStamp * 1000)
        const hour = date.getHours() + 'h00'
        return hour
    }

    return (
        <div style={{ backgroundColor: "rgb(235,235,235)", margin: 1, padding: 5, borderRadius: 5, width: 100, flexShrink: 0, }}>
            <p style={{ textAlign: "center" }}>   {timeConversion(props.time)}</p>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <p>  {props.temp | 0} °C </p>
                <img style={{ marginLeft: 1, alignSelf: "flex-end", width: 30, height: 30 }} src={`https://res.cloudinary.com/dvwjd49at/image/upload/v1648069081/weatherIcons/${props.icon}.png`} alt={props.description} />
            </div>
            <p style={{ textAlign: "center" }}> {props.description} </p>
        </div>
    )
}
export default MeteoHourlyCard