import React, { useState, useEffect } from 'react';
import Layout from "../components/layout";
import Seo from "../components/seo";
import MeteoCard from "../components/MeteoCard";
import MeteoHourlyCard from '../components/MeteoHourlyCard';


function IndexPage() {
  const [meteoForcast, setMeteoForcast] = useState(null)
  const [meteoCurrent, setMeteoCurrent] = useState(null)
  const [error, setError] = useState(null);

  useEffect(() => {
    async function loadingData() {
      const rawForcast = await fetch('https://meteo7d.sebastienk.workers.dev/')
      const rawCurrent = await fetch('https://meteo.sebastienk.workers.dev/')
      
      try {
        const rawForcast = await fetch('https://meteo7d.sebastienk.workers.dev/')
        // const rawForcast = await fetch('https://testmeteo5d.sebastienk.workers.dev/');
        if (!rawForcast.ok) {
          throw new Error('Failed to fetch forecast data');
        }
        const meteoResponse = await rawForcast.json();
        setMeteoForcast(meteoResponse);

        // const rawCurrent = await fetch('https://testmeteo.sebastienk.workers.dev/');
        const rawCurrent = await fetch('https://meteo.sebastienk.workers.dev/')
        if (!rawCurrent.ok) {
          throw new Error('Failed to fetch current weather data');
        }
        const currentMeteoResponse = await rawCurrent.json();
        setMeteoCurrent(currentMeteoResponse);
      } catch (err) {
        setError(err.message);
      }
    }
    
    loadingData()

  }, []);


  function timeConversion(timeStamp) {
    const date = new Date(timeStamp * 1000)
    const hour = date.getHours() + 'h' + date.getMinutes()
    return hour
  }
  let meteoListCurrent
  let meteoForcastMap
  if (meteoForcast !== undefined) {

   // loop to handle the hourly forcast components
    let meteoHourly = []
    for (let i = 0; i < 24; i++) {
      meteoHourly.push(<MeteoHourlyCard time={meteoForcast?.list[i].dt} temp={meteoForcast?.list[i].main.temp} description={meteoForcast?.list[i].weather[0].description} icon={meteoForcast?.list[i].weather[0].icon} />)
    }

    // const sunrise = meteo?.city.sunrise
    // const sunset = meteo?.city.sunset

    // let convDt = meteoCurrent.dt
    const icon = meteoForcast?.list[0].weather[0].icon

    const iconUrl = `https://res.cloudinary.com/dvwjd49at/image/upload/v1648069081/weatherIcons/${icon}.png`

    // Current weather card with hourly forcast in it ______________________________________
    meteoListCurrent = < div className="currentWeather" style={{ display: "flex", alignSelf: "center", flexDirection: "column", height: "53vh", backgroundColor: "rgb(235,235,235)", borderRadius: 10 }}>

      <h2 style={{ marginLeft: 1, alignSelf: "center", marginBottom: 10, marginTop: 10 }}>Paris </h2>
      {/* |0 permet de tronquer les chiffres après la virgule */}
      <h2 style={{ alignSelf: "center", marginBottom: 5 }}>{meteoCurrent?.main.temp | 0} °C </h2>

      <div style={{ display: "flex", justifyContent: "center", marginBottom: 5 }}>
        <p style={{ marginLeft: 10, alignSelf: "center" }}>{meteoCurrent?.weather[0].description}</p>
        <img style={{ marginLeft: 10, alignSelf: "center", width: 50, height: 50 }} src={iconUrl} alt={meteoCurrent?.weather[0].description} />
      </div>

      <p style={{ marginLeft: 1, alignSelf: "center" }} > Temperature ressentie {meteoCurrent?.main.feels_like | 0} °C </p>

      <div style={{ display: "flex", justifyContent: "center", marginTop: 10, marginBottom: 10, }}>
        <p >min {meteoForcast?.list[0].main.temp_min | 0} °C</p>
        <p style={{ marginLeft: 10 }}>max {meteoForcast?.list[0].main.temp_max | 0} °C</p>
      </div>
      {/* Hourly forcast _________________________________________________ */}
      <div style={{ display: "flex", overflowX: "auto" }}>
        {meteoHourly}
      </div>

{/* 
      <p style={{ marginLeft: 10, marginTop: 10 }}>Lever du soleil {timeConversion(sunrise)}</p>
      <p style={{ marginLeft: 10, marginTop: 10 }}>Coucher du soleil {timeConversion(sunset)}</p> */}
      {/* <p style={{ marginLeft: 10, marginTop: 10 }}> Dernière mise à jour des données {timeConversion(convDt)}</p> */}
    </div>
    // Map for the daily forcast components ______________________________
    meteoForcastMap = meteoForcast?.list.map((weatherMap, i) => {

      const tempDate = JSON.stringify(weatherMap.dt)
      return (<MeteoCard key={i} date={tempDate} tempMin={weatherMap.temp_min} tempMax={weatherMap.temp_max} description={weatherMap.weather[0].description} icon={weatherMap.weather[0].icon} />)
    })

  } 
  else {
    meteoListCurrent = < div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "53vh", width: 600, backgroundColor: "rgb(235,235,235)", borderRadius: 10 }}>
      <p style={{ paddingLeft: "10px" }}> Désolé il semblerait que les données météos soient indisponibles pour le moment </p>
    </div>
  }
  return (
    <Layout style={{ height: "100vh" }}>
      <Seo title="Météo" />
      <div style={{ display: "flex", justifyContent: "center", }}>
        {meteoListCurrent}
      </div>
      {/* <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", marginTop: 30 }}>
        {meteoForcastMap}
      </div> */}

    </Layout>)
}

export default IndexPage
